<template>
  <page-skeleton theme="light">
    <section-half-image
        hero
        arrow
        scroll-to="vials-arrow"
        image-path="https://ik.imagekit.io/isotronic/products/vials/two_vials_03b-mCqMN_0b6NON1kez.png?updatedAt=1635262400241"
        img-background="reverse-gradient"
        :alt="$t('products.vials.hero.alt')"
        small-photo
    >
      <template #header>
        {{$t('products.vials.hero.header')}}
      </template>
      <template #content>
        {{$t('products.vials.hero.text')}}
      </template>
    </section-half-image>
    <section-half-image
        id="vials-arrow"
        background="light"
        photo-right-align
        :alt="$t('products.vials.section1.alt')"
        image-path="https://ik.imagekit.io/isotronic/products/vials/vial_red_lighting_E7W8PAEEB_D3eWDIN7_.jpg?updatedAt=1635262857645"
    >
      <template #header>
        {{$t('products.vials.section1.header')}}
      </template>
      <template #content>
        <i18n path="products.vials.section1.text" tag="p">
          <template v-slot:space>
            <div class="my-3"></div>
          </template>
        </i18n>
      </template>
    </section-half-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/vials/vial_with_crack_HlQ6FeuXL_jRVrqO9Y9q.jpg?updatedAt=1635264165482"
        :alt="$t('products.vials.section2.alt')"
    >
      <template #header>
        {{$t('products.vials.section2.header')}}
      </template>
      <template #content>
        <div>
          {{$t('products.vials.section2.text')}}
        </div>
        <ul class="mt-3">
          <li>
            <i18n path="products.vials.section2.feature1.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.vials.section2.feature1.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.vials.section2.feature2.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.vials.section2.feature2.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.vials.section2.feature3.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.vials.section2.feature3.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.vials.section2.feature4.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.vials.section2.feature4.style') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
        <div class="mt-2">
          {{$t('products.vials.section2.text2')}}
        </div>
      </template>
    </section-half-image>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/products/vials/vial_with_lense_84BnPxKXo_MD0-Xegjy.jpg?updatedAt=1635264118266"
        :alt="$t('products.vials.images.alt1')"
    />
    <section-half-image
        background="light"
        photo-right-align
        image-path="https://ik.imagekit.io/isotronic/products/vials/vial_with_dot_-jSbjmsBy.jpg?updatedAt=1635264213457"
        :alt="$t('products.vials.section3.alt')"
    >
      <template #header>
        {{$t('products.vials.section3.header')}}
      </template>
      <template #content>
        <i18n path="products.vials.section3.text" tag="p">
          <template v-slot:break>
            <div class="my-3"></div>
          </template>
          <template v-slot:link>
            <i-link link="Comprehensive Solution">
              {{$t('products.vials.section3.link')}}
            </i-link>
          </template>
        </i18n>
      </template>
    </section-half-image>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";
import ILink from "@/components/utility/SiteLink"

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage,
    ILink
  },
  metaInfo() {
    return {
      title: this.$t('products.vials.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('products.vials.meta.description')}
      ]
    }
  },
}
</script>
